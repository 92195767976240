/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */


// Import mobile navigation fix
@use 'components/fuse-nav-mobile-patch.scss';
// Import responsive table styles
@use 'components/mat-table-responsive.scss';

// Hide UI elements (search, shortcuts, notifications, messages)
search,
shortcuts,
notifications,
messages,
.mat-mdc-menu-item .mat-icon[svgIcon="heroicons_outline:magnifying-glass"],
.mat-mdc-menu-item .mat-icon[svgIcon="heroicons_outline:bolt"],
.mat-mdc-menu-item .mat-icon[svgIcon="heroicons_outline:inbox"],
.mat-mdc-menu-item .mat-icon[svgIcon="heroicons_outline:bell"],
.mat-mdc-menu-item .mat-icon[svgIcon="heroicons_outline:chat-bubble-left-right"] {
  display: none !important;
}

.clickable {
  cursor: pointer;  
}
  
