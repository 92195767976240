// Responsive table styles for Angular Material

// Add specific grid container styles
[matTableResponsive] .grid {
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden !important;
  }
}

.mat-mdc-table[matTableResponsive], 
.mat-table[matTableResponsive] {
  // Make sure CSS variables are applied for styling
  --mat-table-row-item-outline-width: 1px;
  --mat-table-row-item-outline-color: var(--fuse-border, rgba(0, 0, 0, 0.12));
  --mat-table-label-bg: var(--fuse-bg-card, rgba(0, 0, 0, 0.04));
  --mat-table-label-color: var(--fuse-text-secondary, rgba(0, 0, 0, 0.6));
  --mat-table-label-radius: 4px;
  --mat-table-label-font-size: 11px;

  background: var(--fuse-bg-default-rgb);

  // Hide injected headers on desktop
  .mat-cell-header-container {
    display: none;
  }

  // Default styles for regular screens - these should NOT affect the table on large screens
  // Only add styles here that enhance the default table experience

  // Media query for small screens
  @media (max-width: 768px) {
    // Ensure the table doesn't expand beyond screen
    width: 100%;
    table-layout: fixed; // Force the table to respect column widths
    overflow-x: hidden;
    border: 0;
    
    // Hide table header row on mobile
    .mat-mdc-header-row, .mat-header-row {
      display: none !important;
    }
    
    // Convert rows to card-like display on mobile
    .mat-mdc-row, .mat-row {
      display: block;
      height: auto !important;
      padding: 8px 12px;
      margin-bottom: 16px;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      background: var(--fuse-bg-card, rgba(255, 255, 255, 0.04));
      
      // Add some spacing between rows
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
    
    // Style all cells in the row
    .mat-mdc-cell, .mat-cell {
      display: flex !important;
      position: relative;
      width: 100% !important;
      padding: 4px 0 !important;
      border: 0 !important;
      
      // Don't show borders between cells
      border-bottom: none !important;
      
      // Add compact spacing between cells in a row
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
    
    // Make the detected primary data column bold
    .mat-cell-primary-data .mat-cell-content > * {
      font-weight: 500;
    }
    
    // Style the header container
    .mat-cell-header-container {
      display: block;
      width: 35%;
      min-width: 70px;
      max-width: 110px;
      padding-right: 8px;
    }
    
    // Style the injected header labels
    .mat-cell-header {
      display: inline-block;
      font-weight: 500;
      font-size: 12px;
      color: var(--fuse-text-secondary, rgba(0, 0, 0, 0.6));
      
      // Ensure proper alignment with cell content
      &::after {
        content: ":";
        padding-left: 2px;
      }
    }
    
    // Style the content container
    .mat-cell-content {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;
      overflow: hidden; // Prevent content from overflowing
      
      > * {
        max-width: 100%; // Ensure content doesn't exceed container
        overflow: hidden; // Hide overflow
        text-overflow: ellipsis; // Show ellipsis for overflowing text
        word-wrap: break-word; // Allow long words to break
      }
    }
    
    // Special case for action buttons to align correctly
    .mat-cell-action {
      .mat-cell-header-container {
        display: none; // Hide header for action columns
      }
      
      .mat-cell-content {
        width: 100%;
        justify-content: flex-end; // Change to flex-end to align to the right
        margin-left: auto; // Push content to the right
      }
      
      justify-content: flex-end !important; // Change to flex-end
      padding-top: 8px !important;
    }
    
    // Handle avatar/image columns specially
    .mat-cell-avatar {
      justify-content: flex-end !important; // Change to flex-end
      
      .mat-cell-header-container {
        display: none; // Don't show header for avatar columns
      }
      
      .mat-cell-content {
        justify-content: flex-end; // Change to flex-end to align to the right
        margin-left: auto; // Push content to the right
      }
    }
  }
  
  // Additional styles for paginator to make it more compact
  .mat-mdc-paginator, .mat-paginator {
    .mat-mdc-paginator-container, .mat-paginator-container {
      justify-content: center;
      padding: 8px 0;
    }
    
    .mat-mdc-paginator-range-label, .mat-paginator-range-label {
      margin: 0 12px;
    }
  }
}

// For dark theme compatibility
.dark-theme, [class*="theme-dark"] {
  // Use Fuse semantic variables for dark theme
  .mat-mdc-table[matTableResponsive], 
  .mat-table[matTableResponsive] {
    --mat-table-row-item-outline-color: var(--fuse-border, rgba(255, 255, 255, 0.12));
    --mat-table-label-bg: var(--fuse-bg-hover, rgba(255, 255, 255, 0.08));
    --mat-table-label-color: var(--fuse-text-secondary, rgba(255, 255, 255, 0.85));
  }

  @media (max-width: 768px) {
    .mat-mdc-table, .mat-table {
      background-color: var(--fuse-bg);

      .mat-mdc-row, .mat-row {
        background-color: var(--fuse-bg-card, rgba(255, 255, 255, 0.04));
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
      }
      
      .mat-cell-header {
        color: var(--fuse-text-secondary, rgba(255, 255, 255, 0.85));
        font-weight: 600;
      }
      
      // Make primary data column stand out in dark mode
      .mat-cell-primary-data .mat-cell-content > * {
        color: var(--fuse-text-default, rgba(255, 255, 255, 0.9));
      }

      // Improve contrast for all cell content in dark mode
      .mat-cell-content > * {
        color: var(--fuse-text, rgba(255, 255, 255, 0.8));
      }
    }
  }
}
