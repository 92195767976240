/* Mobile navigation fix for when submenus are open */
@media screen and (max-width: 599px) {
  /* Target when the aside menu is open (the presence of fuse-vertical-navigation-aside-wrapper indicates this) */
  .fuse-vertical-navigation-mode-over.fuse-vertical-navigation-opened:has(.fuse-vertical-navigation-aside-wrapper) {
      /* Main wrapper - collapse to icon-only when submenu is open */
      > .fuse-vertical-navigation-wrapper {
          width: 56px !important;
          min-width: 56px !important;
          
          /* Hide text only in main navigation */
          .fuse-vertical-navigation-item-title-wrapper {
              display: none !important;
          }
          
          /* Center icons */
          .fuse-vertical-navigation-item {
              justify-content: center !important;
              padding: 0 16px !important;
          }
          
          /* Hide any extra content */
          .fuse-vertical-navigation-content-header {
              .flex.items-center:not(:has(.w-8)) {
                  display: none !important;
              }
          }
      }
      
      /* Ensure submenu has enough room and adjust its position */
      .fuse-vertical-navigation-aside-wrapper {
          width: calc(100% - 56px) !important;
          left: 56px !important;
          
          /* Override the CSS variable for the mobile view to prevent the submenu from being pushed too far right */
          --fuse-vertical-navigation-width: calc(100vw - 56px) !important;
          max-width: calc(100vw - 56px) !important;
      }
  }
}
