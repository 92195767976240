/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

mat-vertical-stepper {
  border-radius: 6px;
}
.mat-vertical-content-container {
  padding: 0 !important;
}
.mat-vertical-stepper-content {
  padding: 0 !important;
}
.mat-vertical-content {
  padding: 0 24px 8px !important;
}
.mat-vertical-stepper-header .mat-step-text-label {
  margin-top: 4px;
}

mat-slide-toggle.space-between {
  width: 100%;
  & .mdc-form-field {
    width: 100%;
    justify-content: space-between;
  }
  & label {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}